//
// Common area to manage all comms for boats.com
//
import { logger } from '@beacon/common/lang/log'
import { BoatsListing, API_KEY, BoatsProvider } from '.'
import { DateTime } from 'luxon'

//
// See:
//  - https://api.boats.com/docs
//

const log = logger({ package: 'boats', f: 'api' })

//
// Query the API for all listings;
// this only needs minimal information, as the listing fetch happens later.
// returns a list of DocumentID and LastModificationDate
export const fetchListingDocumentIds = async () => {
  const { numResults, results } = await _search({
    offset: '0',
    rows: '2147483647',
    fields: 'DocumentID,LastModificationDate',
  })

  log.debug({ numResults }, 'fetchListingDocumentIds')
  return results || []
}

//
// Performs a fetchListingDocumentIds(), and maps in a provider uri
export const fetchListingsWithUris = async () => {
  return (
    (await fetchListingDocumentIds())
      // map in the provider uri
      .map((e: any) => ({
        ...e,
        uri: `${BoatsProvider.key}::${e.DocumentID}`,
      }))
  )
}

//
// fetch listings since (in days, default last 7 days)
// returns a list containing the URIs
export const fetchListingDocumentIdsSince = async (daysAgo = 7) => {
  const since = DateTime.local().minus({ days: daysAgo }).toISODate()
  let { numResults, results } = await _search({
    modified: since,
    offset: '0',
    rows: '2147483647',
    fields: 'DocumentID,LastModificationDate',
  })

  // map in the provider uri
  const resultsWithUri = results?.map((e: any) => ({
    ...e,
    uri: `${BoatsProvider.key}::${e.DocumentID}`,
  }))

  log.debug({ numResults }, 'fetchListingDocumentIdsSince')
  return resultsWithUri || []
}

//
// fetch a listing by its DocumentID
export const fetchListing = async (DocumentID: string): Promise<BoatsListing> => {
  //
  const method = 'GET'
  let url = new URL(`https://api.boats.com/inventory/${DocumentID}`)
  url.searchParams.append('key', API_KEY)

  log.debug({ method, url }, '_fetchListing')

  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: 'application/vnd.dmm-v1+json, application/json',
    },
  })

  if (!response.ok) {
    const body = await response.text()
    log.error({ status: response.status, statusText: response.statusText, body }, 'fetch failed')
    throw new Error(`Failed to fetch ${url}: ${response.status}`)
  }

  // response content is a "search result" object
  //  { numResults: 1, results: [ { ... } ] }
  // unwrap that.
  const listing: any = await response.json()
  if (!listing || !listing.results || listing.results.length !== 1) {
    log.error({ status: response.status, statusText: response.statusText, listing }, 'fetch failed')
    throw new Error(`Failed to fetch ${url}`)
  }

  return listing.results[0]
}

// See https://api.boats.com/docs for query parameters.
const _search = async (params: { [key: string]: string } = {}): Promise<SearchResponse> => {
  // create the url parameters
  const _params = [['key', API_KEY], ...Object.entries(params)].filter((e) => e && e.length != 0)
  const searchParams = new URLSearchParams(_params)

  let url = new URL(`https://api.boats.com/inventory/search?${searchParams.toString()}`)
  const method = 'GET'

  log.debug({ method, url }, '_search')

  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: 'application/vnd.dmm-v1+json, application/json',
    },
  })

  // response has the shape of:
  //  {"numResults":397,"results":null}
  //  {"numResults":397,"results":[...]}

  if (!response.ok) {
    const body = await response.text()
    log.error({ status: response.status, statusText: response.statusText, body }, 'fetch failed')
    throw new Error(`Failed to fetch ${url}: ${response.status}`)
  }

  return await response.json()
}

interface SearchResponse {
  numResults: number
  results: unknown[] | null
}
