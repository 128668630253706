import { ListingNotFoundFault } from '@beacon/common/lang/faults'
import { logger } from '@beacon/common/lang/log'
import { ListingContent } from '@beacon/common/types'
import { DateTime } from 'luxon'
import { fetchListing } from './_api'
import { BoatsListing } from './index'

const log = logger({ package: 'boats', f: 'fetchListingContent' })

/**
 * Fetch an individual listing for the given uri.
 *
 * @param _bindings empty bindings - not in use
 * @param uri the URI to fetch
 * @returns a string form of the listing
 * @throws ObjectNotFoundFault if the uri is not found in the provider.
 */
export const fetchListingContent = async (_bindings: {}, uri: string): Promise<ListingContent<BoatsListing>> => {
  log.debug({ uri }, 'fetchListingContent')

  // a uri =
  const documentId = uri.split('::')[1]
  const content: BoatsListing = await fetchListing(documentId)

  //
  if (!content) {
    throw new ListingNotFoundFault(`uri not in provider: ${uri}`)
  }

  // patch in a "last modified"
  const when = content.LastModificationDate
  let _lastModified: string | undefined
  if (when) {
    _lastModified = DateTime.fromISO(when).toISO() || undefined
  }

  const listing = {
    uri: uri,
    body: JSON.stringify(content),
    checksums: {
      ...(when && { lastModified: _lastModified }),
    },
  }

  return listing
}
