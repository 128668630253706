<script setup lang="ts">
import debounce from 'lodash.debounce'

const textInputDebounce = debounce((refine: any, text: string) => refine(text), 400)
</script>
<template>
  <!-- <div class="hidden md:flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
    <label for="search-field" class="sr-only">Search</label>
    <ais-search-box class="w-full self-center">
      <template v-slot="{ currentRefinement, refine }">
        <input
          type="search"
          id="search-yachts"
          class="block h-8 w-full z-20 text-sm text-gray-900 rounded-lg border-blue-100 bg-gray-50 border-1"
          placeholder="Search..."
          :value="currentRefinement"
          @input="textInputDebounce(refine, ($event.target! as any).value)"
        />
      </template>
      <template #reset-icon></template>
      <template #submit-icon> </template>
    </ais-search-box>

    <div class="flex items-center px-4">
      <ais-stats
      :class-names="{
        'ais-Stats': 'text-sm text-gray-700',
      }"
      >
        <template v-slot="{ nbHits }">
          <div class="whitespace-nowrap justify-center">{{ nbHits }} listings</div>
        </template>
      </ais-stats>
    </div>

    <div class="flex justify-center px-4">
      <label for="sort" class="sr-only">Sort</label>
      <ais-sort-by
        :class-names="{
          'ais-SortBy': 'flex min-w-48 align-bottom',
          'ais-SortBy-select':
            'block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer',
        }"
        :items="[
          { value: 'global', label: 'Most Relevant' },
          { value: 'global_price_asc', label: 'Price (Low to High)' },
          { value: 'global_price_desc', label: 'Price (High to Low)' },
          { value: 'global_new_desc', label: 'Newest Listings' },
          { value: 'global_updated_desc', label: 'Recently Updated' },
        ]"
      />
    </div>
  </div> -->

  <div class="flex flex-col md:flex-row gap-x-4 lg:gap-x-6 w-full py-2">
    <div class="flex gap-x-4 order-last">
      <!-- stats -->
      <div class="flex items-center">
        <ais-stats
        :class-names="{
          'ais-Stats': 'text-sm text-gray-700',
        }"
        >
          <template v-slot="{ nbHits }">
            <div class="whitespace-nowrap justify-center">{{ nbHits }} listings</div>
          </template>
        </ais-stats>
      </div>

      <!-- order by -->
      <div class="flex justify-center w-full">
        <label for="sort" class="sr-only">Sort</label>
        <ais-sort-by
          :class-names="{
            'ais-SortBy': 'flex align-bottom',
            'ais-SortBy-select':
              'block px-0 w-full text-sm text-gray-500 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer',
          }"
          :items="[
            { value: 'global', label: 'Most Relevant' },
            { value: 'global_price_asc', label: 'Price (Low to High)' },
            { value: 'global_price_desc', label: 'Price (High to Low)' },
            { value: 'global_new_desc', label: 'Newest Listings' },
            { value: 'global_updated_desc', label: 'Recently Updated' },
          ]"
        />
      </div>
    </div>

    <div class="order-first md:flex-1 md:w-full">
      <!-- search text -->
      <label for="search-field" class="sr-only">Search</label>
      <ais-search-box class="w-full self-center">
        <template v-slot="{ currentRefinement, refine }">
          <input
            type="search"
            id="search-yachts"
            class="block h-8 w-full z-20 text-sm text-gray-900 rounded-lg border-blue-100 bg-gray-50 border-1"
            placeholder="Search..."
            :value="currentRefinement"
            @input="textInputDebounce(refine, ($event.target! as any).value)"
          />
        </template>
        <template #reset-icon></template>
        <template #submit-icon> </template>
      </ais-search-box>
    </div>
  </div>
</template>
