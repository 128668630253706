<script setup lang="ts">
//
// * The main view of the application is search results.
//
import { round } from '@beacon/common/lang/util'
import { onBeforeUpdate, ref } from 'vue'
import { priceShort, setBrokenImagePlaceholder } from '../utils/display'
import ListingDetailView from './ListingDetailView.vue'
import SearchResults from './SearchResults.vue'
import SearchResultsObserver from './SearchResultsObserver.vue'
import { Providers } from '@beacon/common/providers'

type Props = {
  navFilter: string
}
const props = defineProps<Props>()

// state tracking for the expanded rows
const expandos = ref([] as any)
const showCopySuccess = ref(false)
onBeforeUpdate(() => {
  expandos.value = []
})

const expando = (_e: Event, uri: string) => {
  // console.log(uri)
  const row = expandos.value[uri] as HTMLElement
  row.classList.toggle('hidden')
}
const showCopyToast = () => {
  showCopySuccess.value = true
  setTimeout(() => {
    showCopySuccess.value = false
  }, 3000)
}
</script>
<template>
  <div>
    <SearchResults :navFilter="props.navFilter" class="h-[calc(100vh-64px)] w-full overflow-y-auto flex flex-col">
      <template #main="{ hits, visibilityChanged, refineNext, isLastPage }">
        <table class="w-full text-sm text-left text-gray-800 table-auto 3xl:table-fixed relative overflow-y-auto mx-auto">
          <thead class="text-xs uppercase">
            <tr>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 md:w-[500px]">Yacht</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-left">Source</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right">Price</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right whitespace-nowrap">Length</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-left">Builder</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right">Cabins</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right">Built</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right">Refit</th>
              <!-- prettier-ignore -->
              <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-left md:w-96">Location &amp; Broker</th>
            </tr>
          </thead>
          <tbody class="overflow-y-scroll w-full">
            <template v-for="hit of hits" :key="hit.objectID">
              <tr 
                class="w-full border-b cursor-pointer transition [&:nth-child(4n+3)]:bg-gray-50 [&:nth-child(4n+3)]:hover:bg-gray-200 hover:bg-gray-200 duration-200"
                aria-expanded="false"
                :id="`header-${hit.objectID}`"
                :aria-controls="`details-${hit.objectID}`"
                @click="(e) => expando(e, hit.objectID)"
              >
                <th scope="row" class="w-full px-4 py-3 font-medium text-gray-900">
                  <div class="flex flex-row">
                    <div class="">
                      <img v-if="hit.thumbnail"
                        class="h-8 w-16 min-w-16 mr-2 object-cover rounded-sm hover:scale-150 duration-300"
                        :src="`${hit.thumbnail}`"
                        alt=""
                        @error="setBrokenImagePlaceholder($event)"
                      />
                      <img v-else
                        class="h-8 w-16 min-w-16 mr-2 object-cover rounded-sm hover:scale-150 duration-300"
                        :src="`/broken-placeholder.ignore`"
                        @error="setBrokenImagePlaceholder($event)"
                        alt=""
                      />
                    </div>
                    <div class="grow whitespace-nowrap truncate">
                      <span class="text-bold"> {{ hit.buildYear }} {{ hit.model }} </span><br />
                      <span class="text-xs uppercase text-gray-600"> {{ hit.name }}</span>
                    </div>
                    <!-- Badges -->
                    <div class="flex items-center justify-center" v-if="hit.labels">
                      <span
                        v-if="hit.labels.includes('sold')"
                        class="inline-flex items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
                        >SOLD</span
                      >
                    </div>
                  </div>
                </th>
                <!-- Source -->
                 <td class="px-4 py-3 text-left">
                  <span>{{ Providers[hit.provider].name }}</span>
                 </td>
                <!-- Price -->
                <td class="px-4 py-3 text-right">
                  <span v-html="priceShort(hit.priceAsking) || '&mdash;'"></span>
                </td>
                <!-- Length -->
                <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                  <span v-html="round(hit.length, 1) + 'm (' + (hit.length * 3.2808398950131).toFixed(0) + 'ft)' || '&mdash;'"></span>
                  <!-- {{ (hit.length * 3.2808398950131).toFixed(0) }} ft -->
                </td>
                <!-- Builder -->
                 <td class="px-4 py-3 text-left">
                  <span>{{ hit.builder || '&mdash;' }}</span>
                 </td>
                <!-- Cabins -->
                <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                  {{ hit.cabins || '&mdash;' }}
                </td>
                <!-- Built -->
                <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                  {{ hit.buildYear || '&mdash;' }}
                </td>
                <!-- Refit -->
                <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                  {{ hit.refitYear || '&mdash;' }}
                </td>
                <!-- Location -->
                <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-left">
                  {{ hit.location }}<br />
                  <span class="truncate">{{ hit.broker?.company || '&nbsp;' }}</span>
                </td>
              </tr>
              <!-- expando details-->
              <tr
                class="hidden flex-1 overflow-x-auto w-full border-b"
                :id="`details-${hit.objectID}`"
                :ref="
                  (el) => {
                    expandos[hit.objectID] = el
                  }
                "
                :aria-labelledby="`header-${hit.objectID}`"
              >
                <td colspan="100%">
                  <ListingDetailView :hit="hit" @showCopySuccess="showCopyToast()" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <template v-if="!isLastPage">
          <SearchResultsObserver :refineNext="refineNext" @visibilityChanged="visibilityChanged" />
        </template>
      </template>
    </SearchResults>
  </div>
</template>