import { logger } from '@beacon/common/lang/log'
import { requestListingUpdate } from '@beacon/common/services'
import { findActiveProviderListings } from '@beacon/common/services/findActiveProviderListings'
import { DateTime } from 'luxon'
import { fetchListingDocumentIdsSince } from './_api'
import { BoatsProvider } from './index'

const log = logger({ package: 'boats', f: 'refreshListings' })

/**
 * Issue refresh operations for all the current listings.
 *
 * @param bindings
 * @returns a Promise of the operations
 */
export const refreshListings = async (bindings: {
  FEED_FETCH_QUEUE: Queue
  LISTING_ACTIVE: KVNamespace<string>
  ID_CHART: KVNamespace
}): Promise<any> => {
  // how far to distribute the refreshes

  const [existingListings, updatedListings] = await Promise.all([
    findActiveProviderListings(bindings, BoatsProvider.key),
    fetchListingDocumentIdsSince(),
  ])

  //  UPDATED LISTINGS intersection BEACON ACTIVE LISTINGS = listings to be fetched.
  const _existingListings = new Set(existingListings)
  const _updatedListings = new Set(updatedListings.map((e) => e.uri))

  const _toRefresh = Array.from(_updatedListings.intersection(_existingListings))
  const listingsToRefresh = Array.from(_toRefresh)

  // update the changed listings.
  log.info({ n: listingsToRefresh.length }, 'num listings to refresh')
  return requestListingUpdate(bindings, listingsToRefresh, { force: true, lastChecked: DateTime.utc().toISO() })
}
