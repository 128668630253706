import { logger } from '@beacon/common/lang/log'
import { createListing, findExistingListings } from '@beacon/common/services'
import { fetchListingsWithUris } from './_api'
import { BoatsProvider } from './index'

const log = logger({ package: 'boats', f: 'createNewListings' })

/**
 * Find new listings for this provider
 *
 * @param bindings
 * @returns a Promise of all the operations
 */
export const createNewListings = async (bindings: {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  FEED_SCANNER_TRIGGER_QUEUE: Queue
  ID_CHART: KVNamespace
}): Promise<any> => {
  //
  const [boatsListings, existingListings] = await Promise.all([
    // Get Active Vessel MLSIDs from YATCO api.
    fetchListingsWithUris(),
    // Get Beacon active URIs
    findExistingListings(bindings, BoatsProvider.key),
  ])

  // Compare
  const _boatsListings = new Set(boatsListings.map((e) => e.uri))
  const _existingListings = new Set(existingListings)
  const newListingIds: any[] = Array.from(_boatsListings.difference(_existingListings))
  log.info(
    {
      newListingIds: newListingIds.length,
      boatsListings: boatsListings.length,
      existingListings: existingListings.length,
    },
    'discovery',
  )

  // create listings for the items that are new
  log.info({ n: newListingIds.length }, 'Number of new listings')
  return createListing(bindings, BoatsProvider.key, newListingIds)
}
