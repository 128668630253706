import { logger } from '@beacon/common/lang/log'
import { round, toEpochSeconds } from '@beacon/common/lang/util'
import { AssetMap, Chronicle, IndexHit, ListingContent, ListingInfo } from '@beacon/common/types'
import { BoatsListing } from './index'

const log = logger({ package: 'boats', f: 'asIndexHit' })

/**
 * Map an BoatsOnline item to an Algolia IndexItem
 *
 * @param info the listing information
 * @param raw the raw listing from the provider
 */
export const asIndexHit = (
  inf: ListingInfo,
  content: ListingContent<BoatsListing>,
  chron: Chronicle,
  assetMap: AssetMap,
): IndexHit => {
  let raw: BoatsListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  const images = assetMap.map(raw.Images.map((image) => image.Uri))
  const imagesCount = images?.length || 0

  const iso = raw.OriginalPrice?.replace(/[0-9.,]/g, '').trim() || ''

  // NB: Keep the type shape for clarity in the code.
  const hit: IndexHit = {
    objectID: inf.uri,
    brochureId: inf.uriBrochure.split('::').pop() ?? '',
    providerId: inf.uriProvider.split('::').pop() ?? '',
    // labels are set after this hit is created
    labels: [],
    length: raw.NormNominalLength,
    model: raw.Model,
    name: raw.BoatName?.trim() || raw.Model,
    priceAsking: { amount: raw.NormPrice, iso: iso },
    provider: inf.provider,
    //
    beam: round(raw.BeamMeasure),
    broker: {
      company: undefined,
      name: raw.SalesRep?.Name || undefined,
      email: undefined,
      phoneNumber: undefined,
    },
    buildYear: raw.ModelYear,
    builder: raw.BuilderName,
    cabins: undefined,
    // decksCount?: number // how many decks
    draft: undefined,
    engineCount: undefined,
    engineHours: undefined,
    // flag?: string // the flag of the yacht
    fuelCapacity: undefined,
    images: images,
    imagesCount: imagesCount,
    location: raw.BoatLocation?.BoatStateCode || undefined,
    // locationCity?: string // the city where the yacht is located
    // locationCountry?: string // the country where the yacht is located
    // pricePrevious?: Price // Previous price of the yacht
    // refitType?: string // Type of refit
    // refitYear?: number // Year when the yacht was refitted
    speedCrusing: round(raw.CruisingSpeedMeasure),
    speedMax: round(raw.MaximumSpeedMeasure),
    thumbnail: assetMap.get(raw.Images[0].Uri),
    tsChecked: toEpochSeconds(chron.lastChecked),
    tsListingPublished: toEpochSeconds(raw.ItemReceivedDate),
    tsListingUpdated: toEpochSeconds(raw.LastModificationDate),
    tsSold: undefined,
    type: undefined,
    typeSub: undefined,
  }

  // There is only 1 label ever set for this provider;
  // SalesStatus: Active, Inactive, Sold, Delete, Sale Pending, On-Order, Trade-In Pending, Expired, Rejected
  // 'Sold' matches our 'sold' label
  if (raw.SalesStatus) {
    hit.labels = [raw.SalesStatus.toLowerCase()]
  }

  // algolia records have a cap of 10kb, so we need to be careful with the size of the record.
  // if we need to remove some fields, we can do so now. (images)
  let passes = 0
  // while there are images to trim, the recod is too large, and we haven't tried too many times.
  while (hit.images?.length && JSON.stringify(hit).length > 10000 && passes++ < 500) {
    hit.images = hit.images?.slice(0, hit.images.length - 1)
  }
  const hitLength = JSON.stringify(hit).length
  if (hitLength > 10000) {
    log.error({ length: hitLength, uri: inf.uri }, 'record length is > 10k')
  }

  return hit
}
