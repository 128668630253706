import type { Asset, ListingContent } from '@beacon/common/types'
import type { BoatsListing } from './index'

/**
 * Extract the assets from the raw source.
 *
 * @param content the ListingContent containing the BoatsListing
 * @returns an array of Assets within the content
 */
export const extractAssets = (content: ListingContent<BoatsListing>): Asset[] => {
  let raw: BoatsListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  // "Images" is an array of objects like this:
  //  {
  //    "Priority": "0",
  //    "Caption": "",
  //    "Uri": "https://images.boatsgroup.com/images/1/42/30/6994230_20190220201750253_1_XLARGE.jpg",
  //    "LastModifiedDateTime": "2019-02-20T20:17:51-08:00"
  //  },
  const imageUrls: string[] = raw.Images.map((image) => image.Uri)

  // make sure they are unique.
  const set = new Set(imageUrls)
  return Array.from(set).map((src) => ({ src }))
}
